



import { Vue, Component } from 'vue-property-decorator'
import Regin from '../RegionManagement/AreaPage/index.vue'
import store, { actionTypes } from '@/store'

@Component({
  components: {
    Regin
  }
})
export default class ReginPage extends Vue {
  getTree() {
    return store.dispatch(actionTypes.FETCH_BASE_AREAS, true)
  }

  delArea(id: string) {
    return this.$api.base.getAreaDelete(id)
  }

  createArea(data: AnyObj) {
    return this.$api.base.getAreaCreate(data)
  }

  putArea(id: string, data: AnyObj) {
    return this.$api.base.getEditAreaList(id, data)
  }
}
