
















































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue'
import editDialog from './editDialog.vue'
import TreeRender from './tree_render.vue'
import EquipPage from '../EquipPage/index.vue'
import _ from 'lodash'
import pageTitle from '@/components/common/pageTitle.vue'
import InputSearch from '@/components/common/InputSearch.vue'

@Component({
  name: 'region',
  components: {
    editDialog,
    TreeRender,
    EquipPage,
    pageTitle,
    InputSearch
  }
})
export default class Regon extends Vue {
  @Ref() readonly leftTreeNode: Vue
  @Ref() readonly editDialogNode: editDialog
  @Prop({ default: () => {} }) getArea: Function
  @Prop({ default: () => {} }) deleteArea: Function

  get user() {
    return this.$store.state.user || {}
  }

  private tableHeight: null | number = null
  private filterLeftText: null | string = null
  private left: any = {
    list: [],
    parentId: '',
    checkId: '',
    openIds: [],
    loading: false
  }
  private non_maxexpandId = 1000
  private treeShow = true
  private currentAreaId = ''
  private areaList: any[] = []

  async created() {
    await this.getAreasList()
  }

  formatNodeLabel(value: string) {
    if (this.filterLeftText) {
      const regex = new RegExp(this.filterLeftText, 'g')
      return value.replace(regex, '<span style="color: #f50;">' + this.filterLeftText + '</span>')
    }

    return value
  }

  private watchInput(val: string) {
    if (val === '') {
      this.filterLeftText = null
      this.leftTreeNode.search()
    }
  }
  private searchRegion(val: string) {
    this.filterLeftText = val
    if (this.left.list.length === 0) {
      this.getAreasList()
    }
    if (this.filterLeftText === null || this.filterLeftText.match(/^[ ]*$/)) {
      return false
    } else {
      this.leftTreeNode.search(this.filterLeftText)
    }
  }
  private async getAreasList() {
    this.$message.closeAll()

    let resp
    this.left.loading = true
    try {
      resp = await this.getArea()
      this.left.loading = false
    } catch (error) {
      this.left.loading = false
    }
    if (resp) {
      const arrList: any[] = resp
      this.left.list = arrList
      this.left.parentId = arrList[0] && arrList[0].id
      this.areaList = arrList
      arrList.forEach(({ id }) => {
        const ids = []
        ids.push(id)
        this.editDialogNode.init(arrList, ids)
        this.refeshLeftTree(this.left.list)
      })
      setTimeout(() => {
        this.leftTreeNode.search(this.filterLeftText)
      }, 0)
      this.currentAreaId = this.left.parentId
      this.left.checkId = this.left.parentId
      this.$nextTick(() => {
        this.leftTreeNode.setCurrentKey(this.currentAreaId)
      })
    }
  }
  private filterNode(value: string, data: AnyObj) {
    if (!value) return true
    return data.name.indexOf(value) !== -1
  }
  private async clickAdd() {
    this.treeShow = true
    this.editDialogNode.open('add', {
      parentId: this.left.checkId
        ? this.left.checkId
        : this.left.list.length > 0
        ? this.left.list[0].id
        : null
    })
  }
  private message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }
  private refeshLeftTree(children: any) {
    const stak: any[] = []

    function getIds(list: any[], id: string) {
      let index = -1
      if (list && list.length) {
        list.every((e, i) => {
          if (e.id === id) {
            index = i
          } else {
            if (getIds(e.children, id) !== -1) {
              index = i
            }
          }
          return index === -1
        })
      }
      if (index !== -1) {
        stak.push(index)
      }
      return index
    }

    if (this.left.checkId) {
      getIds(this.left.list, this.left.checkId)
      const ids: any[] = []
      let list = children
      stak.reverse().forEach(e => {
        ids.push(list[e].id)
        list = list[e].children
      })
      this.left.openIds = ids
      return
    }
    const child = _.cloneDeep(children)
    if (!child[0].children) {
      child[0].children = []
    }
    if (!child[0].children[0]) {
      child[0].children[0] = []
    }
    this.left.openIds = [child[0].id, child[0].children[0].id]
  }
  private leftTreeClick(list: AnyObj) {
    this.left.checkId = list.id
    this.currentAreaId = list.id // 将当前区域id传到点位管理组件
  }
  private renderContent(h: CreateElement, { label, node, data, store }: any) {
    return h(TreeRender, {
      props: {
        LABEL: label,
        DATA: data, //节点数据
        NODE: node, //节点内容
        STORE: store //完整树形内容
      },
      on: {
        //绑定方法
        nodeEdit: (s: any, d: any) => this.handleEdit(s, d),
        nodeDel: (s: any, d: any, n: any) => this.handleDelete(s, d, n)
      }
    })
  }

  private handleEdit(s: any, d: AnyObj) {
    //编辑节点
    this.treeShow = d.parentId ? true : false
    this.editDialogNode.open('edit', d)
  }
  handleDelete(s: any, d: AnyObj, n: AnyObj) {
    //删除节点
    //有子级不删除
    if (d.children && d.children.length !== 0) {
      this.$message.error('此节点有子级，不可删除！')
      return false
    } else {
      //删除操作
      const delNode = () => {
        const list: any[] = n.parent.data.children || n.parent.data
        //节点同级数据，顶级节点时无children
        let _index = 99999 //要删除的index
        list.map((c, i) => {
          if (d.id == c.id) {
            _index = i
          }
        })
        list.splice(_index, 1)
      }
      const isDel = () => {
        this.$confirm('区域一经删除无法恢复，基于区域的统计数据也将删除，是否确定删除？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
          cancelButtonClass: 'message-cancel-btn'
        })
          .then(async () => {
            const resp = await this.deleteArea(d.id)
            if (resp && resp.status === 204) {
              this.message('删除成功!', 'success')
              this.left.checkId = null
              this.getAreasList()
            }
          })
          .catch(() => {
            return false
          })
      }
      //新增节点直接删除，否则要通过请求数据删除
      d.id > this.non_maxexpandId ? delNode() : isDel()
    }
  }
}
