































import { actionTypes } from '@/store'
import { tag } from '@/utils/options'
import { Vue, Component } from 'vue-property-decorator'

type Tag = AnyObj

@Component({ components: {} })
export default class BindTags extends Vue {
  private visible = false
  private id = ''
  private formData: { tagIds: string[] } = { tagIds: [] }
  private loading = false

  get tags(): Tag[] {
    return this.$store.state.tags
  }

  get tagOptions() {
    return tag.types.map(({ value, label }) => ({
      value,
      label,
      children: this.tags
        .filter(({ type }) => type === value)
        .map(({ id, name }) => ({ value: id, label: name }))
    }))
  }

  public async open(value: AnyObj) {
    this.$store.dispatch(actionTypes.FETCH_TAGS)

    this.id = value.id
    this.visible = true
    this.$nextTick(async () => {
      const { data } = await this.$api.base.getDeviceTags(this.id)
      if (data && data.code === 0) {
        const tagIds = data.data.map((i: AnyObj) => i.id)
        this.formData.tagIds = tagIds
      }
    })
  }
  private close() {
    this.visible = false
  }
  private async submit() {
    const { tagIds } = this.formData
    if (tagIds.length === 0) {
      this.$message({ message: '至少选择一个标签进行绑定', type: 'error' })
      return
    }
    this.loading = true
    const { data } = await this.$api.base.postDeviceTags(this.id, { tagIds })
    if (data && data.code === 0) {
      this.visible = false
      this.$message({ message: '绑定成功', type: 'success' })
    }
    this.loading = false
  }
}
