















































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { judgeValue } from '@/utils'
import validator from '@/components/common/validator'
import InputSearch from '@/components/common/InputSearch.vue'
import _ from 'lodash'
import store, { actionTypes } from '@/store'
import { ElForm } from 'element-ui/types/form'

@Component({ components: { InputSearch } })
export default class RegionEdit extends Vue {
  @Prop({}) private treeShow: boolean
  @Prop({ default: () => {} }) editArea: Function
  @Prop({ default: () => {} }) addArea: Function
  @Ref() readonly formNode: ElForm
  @Ref() inputSearch: InputSearch
  @Ref() readonly editTreeNode: Vue

  private filterText: null | string = ''
  private showFlag = false
  private loading = false
  private title = ''
  private list = []
  private form: AnyObj = {
    name: '',
    description: null,
    parentId: null,
    capacity: null,
    id: null,
    longitude: null,
    latitude: null
  }
  private project = 'star'
  private rules = {
    parentId: [{ required: true, message: '请选择所属区域', trigger: 'blur' }],
    name: [
      {
        required: true,
        validator: validator.checkName({
          num: 256,
          error1: '请输入区域名称',
          error2: '区域名称应在256字符内，请重新输入'
        }),
        trigger: 'blur'
      }
    ],
    description: [{ min: 0, max: 200, message: '备注应在200字符内，请重新输入', trigger: 'blur' }],
    longitude: [{ validator: validator.longitude({}), trigger: 'blur' }],
    latitude: [{ validator: validator.latitude({}), trigger: 'blur' }]
  }
  deactivated() {
    this.showFlag = false
  }
  changeRules({ validate = false }) {
    this.rules.latitude = [
      {
        validator: validator.latitude({ type: !this.form.longitude }),
        trigger: 'blur'
      }
    ]
    this.rules.longitude = [
      {
        validator: validator.longitude({ type: !this.form.latitude }),
        trigger: 'blur'
      }
    ]
    if (validate) this.formNode.validate()
  }
  private watchInput(val: string) {
    if (val === '') {
      this.filterText = null
      this.editTreeNode.search(val)
    }
  }
  private searchArea(val: string) {
    this.filterText = val
    if (this.filterText === null || this.filterText.match(/^[ ]*$/)) {
      return false
    } else {
      this.editTreeNode.search(this.filterText)
    }
  }
  private cancelDialog() {
    this.showFlag = false
  }
  private message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }
  private getList(list: any, ids: any) {
    list.map((e: AnyObj) => {
      // e.disabled = ids.includes(e.id);
      if (e.children) {
        e.children = this.getList(e.children, ids)
      }
    })
    return list
  }
  public init(list: any, ids: any) {
    this.list = this.getList(list, ids)
  }

  private async getAreaInfo() {
    this.areaLoading = true
    const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
    if (areas) {
      const arrList: any = Array.from(areas)
      arrList.push(areas)
      this.list = arrList
    }
    this.areaLoading = false
  }
  private filter(value: string, data: AnyObj) {
    if (!value) return true
    return data.name.indexOf(value) !== -1
  }
  public async open(type: string, data: any = {}) {
    this.showFlag = true
    if (this.list.length === 0) {
      this.getAreaInfo()
    }
    this.$nextTick(() => {
      this.formNode.resetFields()
      if (this.inputSearch) this.inputSearch.restore()
      this.filterText = '' // 每次打开都清空搜索框中的内容
      if (type === 'add') {
        this.title = '新增区域'
        this.form.name = ''
        this.form.description = ''
        this.form.parentId = ''
        this.form.capacity = ''
        this.form.longitude = ''
        this.form.latitude = ''
        this.setChecked(data)
      } else if (type === 'edit') {
        this.title = '编辑区域信息'

        this.form.name = data.name
        this.form.id = data.id
        this.form.description = data.description
        this.form.parentId = data.parentId
        this.form.capacity = data.capacity

        if (data.coordinates) {
          const coordinates = data.coordinates.split(',')
          this.form.longitude = coordinates[0]
          this.form.latitude = coordinates[1]
        }
        this.setChecked(data)
      }
      this.changeRules({})
    })
  }
  submit() {
    this.formNode.validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true
        let res
        const createArea = {
          name: this.form.name,
          description: this.form.description,
          parentId: this.form.parentId,
          capacity: this.form.capacity,
          id: this.form.id
        }
        const { longitude, latitude } = this.form
        const coordinates = longitude && latitude ? longitude + ',' + latitude : null
        Object.assign(createArea, { coordinates })
        try {
          if (this.title === '新增区域') {
            delete createArea.id
            judgeValue(createArea)

            res = await this.addArea(createArea)
            if (res && res.data && res.data.code === 0) {
              this.$emit('refresh')
              this.message('新增成功', 'success')
              this.showFlag = false
            } else {
              this.loading = false
            }
          } else {
            judgeValue(this.form)
            judgeValue(createArea)
            delete createArea.parentId
            const scope = this.$store.state.dashboard?.user?.scope || ''
            const d = _.cloneDeep(createArea)
            if (d.id === scope) {
              delete d.parentId
            }
            res = await this.editArea(this.form.id, d)
            if (res.data && res.data.code === 0) {
              this.$emit('refresh')
              this.message('编辑成功', 'success')
              this.showFlag = false
            } else {
              this.loading = false
            }
          }
        } catch (error) {
          this.loading = false
          return false
        }
      }
    })
  }
  private change(item: any, node: any) {
    if (node == true || this.form.parentId == item.id) {
      //点击已选中复选框，保证至少一个选中
      this.setChecked({ parentId: item.id })
    }
  }
  private setChecked({ parentId }: any) {
    this.form.parentId = parentId
    if (parentId) {
      this.$nextTick(() => {
        if (this.editTreeNode) this.editTreeNode.setCheckedKeys([parentId])
      })
    } else {
      this.$set(this.form, 'parentId', '')
    }
  }
  private treeClick({ id, disabled }: any) {
    if (!disabled) {
      this.setChecked({ parentId: id })
    }
  }
}
