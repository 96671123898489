































import { Vue, Component, Prop } from 'vue-property-decorator'
import VNode from '@/components/common/VNode.vue'

@Component({ components: { VNode } })
export default class TreeRender extends Vue {
  @Prop({}) private LABEL: any
  @Prop({}) private NODE: any
  @Prop({}) private DATA: any
  @Prop({}) private STORE: any

  private showDeleMsg = false

  private nodeEdit(s: any, d: any, n: any) {
    //编辑
    this.$emit('nodeEdit', s, d, n)
  }
  private nodeDel(s: any, d: any, n: any) {
    //删除
    this.showDeleMsg = true
    this.$emit('nodeDel', s, d, n)
  }
}
