






























































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { judgeValue } from '@/utils'
import validator from '@/components/common/validator'
import InputSearch from '@/components/common/InputSearch.vue'
import { ElForm } from 'element-ui/types/form'
import _ from 'lodash'
import store, { actionTypes } from '@/store'
import BindDevice from './bindDevice.vue'

@Component({ components: { InputSearch, BindDevice } })
export default class EquipEdit extends Vue {
  @Ref() readonly editTreeNode: Vue
  @Ref() readonly formNode: ElForm
  @Ref() inputSearch: InputSearch
  @Ref() readonly BindDeviceNode: BindDevice
  private filterText: string | null = ''
  private showFlag = false
  private loading = false
  private selectArea: any[] = []
  private ruleForm: any = {
    physicalId: null,
    middleNum: '',
    middleId: '',
    createdAt: '',
    description: null,
    checkId: '',
    parent: '',
    id: '',
    nvrId: '',
    channelId: '',
    longitude: '',
    latitude: ''
  }
  private rules = {
    description: [{ required: false, message: '请填写备注用于设备区分或其他', trigger: 'blur' }],
    checkId: [{ required: true, message: '请选择所属区域', trigger: 'blur' }],
    channelId: [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value && !/^[1-9]$|^[12][0-9]$|^3[0-2]$/.test(value)) {
            callback(new Error('通道号应为1～32之间的整数值'))
          }
          callback()
        },
        trigger: 'blur'
      }
    ],
    physicalId: [
      {
        required: true,
        message: '请选择设备',
        trigger: 'blur'
      }
    ],
    longitude: [{ validator: validator.longitude({}), trigger: 'blur' }],
    latitude: [{ validator: validator.latitude({}), trigger: 'blur' }]
  }
  private areaLoading = false
  mode = ''
  show = true

  get user() {
    return this.$store.state.user || {}
  }

  get title() {
    return this.mode === 'add' ? '添加设备' : '编辑设备信息'
  }

  @Watch('showFlag')
  watchFlag() {
    if (!this.showFlag) {
      this.formNode.resetFields()
    }
  }
  deactivated() {
    this.showFlag = false
  }
  changeRules({ validate = false }) {
    this.rules.latitude = [
      {
        validator: validator.latitude({ type: !this.ruleForm.longitude }),
        trigger: 'blur'
      }
    ]
    this.rules.longitude = [
      {
        validator: validator.longitude({ type: !this.ruleForm.latitude }),
        trigger: 'blur'
      }
    ]
    if (validate) this.formNode.validate()
  }
  private watchInput(val: string) {
    if (val === '') {
      this.filterText = null
      this.editTreeNode.search(val)
    }
  }
  private searchArea(val: string) {
    this.filterText = val
    if (this.filterText === null || this.filterText.match(/^[ ]*$/)) {
      return false
    } else {
      this.editTreeNode.search(this.filterText)
    }
  }

  private cancelDialog() {
    this.showFlag = false
  }
  private message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }
  private getList(list: any[]) {
    list.map(e => {
      if (e.children) {
        e.children = this.getList(e.children)
      }
    })
    return list
  }
  public init(list: any) {
    const data = _.cloneDeep(list)
    if (data && data[0]) {
      data[0].disabled = true // 根区域下不能直接新建点位
    }
    this.selectArea = this.getList(data)
  }
  private async getAreasList() {
    this.areaLoading = true
    const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
    if (areas) {
      const arrList = Array.from(areas)
      arrList.push(areas)
      this.init(arrList)
    }
    this.areaLoading = false
  }
  selectDevice() {
    this.BindDeviceNode.open()
    this.show = false
  }
  bindDevice(physicalId?: string, deviceId?: string) {
    if (physicalId) this.ruleForm.physicalId = physicalId
    if (deviceId) this.ruleForm.deviceId = deviceId
    this.show = true
  }
  public async open(type: string, data: any = {}, areaId?: any) {
    if (this.selectArea.length === 0) {
      this.getAreasList()
    }
    this.showFlag = true
    this.mode = type
    this.$nextTick(() => {
      if (this.inputSearch) this.inputSearch.restore()
      if (type === 'add') {
        this.ruleForm.checkId = areaId
        this.filterText = '' // 每次打开都清空搜索框中的内容
        // this.ruleForm.name = null
        this.ruleForm.type = null
        this.ruleForm.physicalId = null
        this.ruleForm.nvrId = null
        this.ruleForm.channelId = null
        this.ruleForm.longitude = null
        this.ruleForm.latitude = null
        this.setChecked([areaId]) // 新建点位时，所属区域默认选中当前所在的区域
      } else if (type === 'edit') {
        this.ruleForm.checkId = data.areaId
        // this.ruleForm.name = data.name
        this.ruleForm.middleNum = data.deviceTypeName
        this.ruleForm.physicalId = data.physicalId
        this.ruleForm.middleId = data.physicalId
        this.ruleForm.id = data.id
        this.ruleForm.description = data.description
        this.ruleForm.nvrId = data.nvrId
        this.ruleForm.channelId = data.channelId
        if (data.coordinates) {
          // const coordinates = JSON.parse(data.coordinates)
          const coordinates = data.coordinates.split(',')
          this.ruleForm.longitude = coordinates[0]
          this.ruleForm.latitude = coordinates[1]
        }
        this.setChecked([data.areaId])
      }
      this.changeRules({})
    })
  }
  private submit() {
    this.formNode.validate(async valid => {
      if (valid) {
        this.loading = true
        let res
        const Location: any = {
          areaId: this.ruleForm.checkId,
          physicalId: this.ruleForm.physicalId,
          description: this.ruleForm.description,
          channelId: this.ruleForm.channelId ? Number(this.ruleForm.channelId) : null,
          nvrId: this.ruleForm.nvrId
        }
        const { longitude, latitude } = this.ruleForm
        const coordinates = longitude && latitude ? longitude + ',' + latitude : null
        Object.assign(Location, { coordinates })
        judgeValue(Location)
        if (this.mode === 'add') {
          try {
            res = await this.$api.base.bindDevice(this.ruleForm.deviceId, Location.areaId)
            if (res.data && res.data.code === 0) {
              this.message('新增成功', 'success')
              this.$emit('refresh')
              this.showFlag = false
            } else {
              this.loading = false
              return false
            }
          } catch (err) {
            this.loading = false
          }
        } else {
          try {
            res = await this.$api.base.getEditDevice(this.ruleForm.id, {
              physicalId: Location.physicalId,
              description: Location.description,
              channelId: Location.channelId,
              nvrId: Location.nvrId,
              coordinates: Location.coordinates,
              areaId: Location.areaId
            })
            if (res.data && res.data.code === 0) {
              this.message('编辑成功', 'success')
              this.$emit('refresh')
              this.showFlag = false
            } else {
              this.loading = false
              return false
            }
          } catch (err) {
            this.loading = false
          }
        }
      }
    })
  }
  private setChecked(ids: any[] = []) {
    this.$nextTick(() => {
      this.editTreeNode.setCheckedKeys(ids)
    })
  }
  private change(item: AnyObj, node: boolean) {
    if (node == true || this.ruleForm.checkId == item.id) {
      this.ruleForm.checkId = item.id
      this.setChecked([item.id])
    }
  }
  private treeClick({ id, disabled }: any) {
    if (!disabled) {
      this.ruleForm.checkId = id
      this.setChecked([id])
    }
  }
  private filter(value: string, data: AnyObj) {
    if (!value) return true
    return data.name.indexOf(value) !== -1
  }
}
