






























































































import { Vue, Component, Ref } from 'vue-property-decorator'
import { variables } from '@/libs/theme'
import pagination from '@/components/common/pagination/index.vue'
import { tableScrollTop } from '@/utils/dom'

@Component({
  name: 'BindDevice',
  components: { pagination }
})
export default class BindDevice extends Vue {
  @Ref('paginationNode') readonly paginationNode: pagination
  private visible = false
  private deviceId = ''
  private filterData = {}
  private deviceOptions = []
  private deviceList = []
  private shadow9Color = variables.shadow9Color
  private loading = false
  private query = {
    limit: 20,
    offset: 0
  }

  created() {}
  open(id?: string) {
    if (id) this.deviceId = id
    this.visible = true
    this.fetchDeviceTypes()
    this.getDeviceList()
  }
  private close() {
    this.filterData = {}
    this.paginationNode.initPage({ current: 1 })
    this.$emit('close')
  }

  private handleFilter() {
    this.getDeviceList(this.query.limit, 0)
    this.paginationNode.initPage({ current: 1 })
  }

  async getDeviceList(pageSize = 20, offset = 0) {
    this.loading = true
    try {
      const { physicalId, type }: any = this.filterData
      const params: AnyObj = {
        limit: pageSize,
        offset: offset,
        bind: false,
        types: type ? [type] : null,
        physicalId:
          physicalId && !physicalId.match(/^[ ]*$/) ? physicalId.replace(/\s+/g, '') : null
      }

      const { data } = await this.$api.base.getDevicesSearch(params)

      if (data && data.code === 0) {
        const { count } = data
        this.deviceList = data.data
        this.paginationNode.init({ total: count ? count : 0 })
        tableScrollTop()
      }
    } finally {
      this.loading = false
    }
  }
  pageChange(data: AnyObj) {
    this.query.limit = data.pageSize
    this.query.offset = (data.pageNo - 1) * data.pageSize
    this.getDeviceList(this.query.limit, this.query.offset)
  }
  async fetchDeviceTypes() {
    const { data } = await this.$api.base.getDeviceTypes()
    if (data.code === 0) {
      this.deviceOptions = data.data
    }
  }
  async bind(row: AnyObj) {
    const { id, physicalId } = row
    if (this.deviceId) {
      // 逻辑
      // todo physicalId 没用了
      const { data } = await this.$api.base.bindDevice(this.deviceId, id)
      if (data && data.code === 0) {
        this.$message({ message: '绑定成功', type: 'success' })
        this.visible = false
        this.$emit('close')
      }
    } else {
      this.visible = false
      this.$emit('close', physicalId, id)
    }
  }
}
