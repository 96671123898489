
























































































































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import moment from 'moment'
import pagination from '@/components/common/pagination/index.vue'
import asyncPool from '@/utils/async-pool'
import editDialog from './editDialog.vue'
import BindTags from './bindTags.vue'
import { ElTable } from 'element-ui/types/table'
import { tableScrollTop } from '@/utils/dom'
import { variables } from '@/libs/theme'
import treeFind from '@/libs/operation-tree-node/treeFind'
import { blobToJson } from '@/utils/helpers'
import Modal from '@/components/common/Modal'
import PreviewDialog from './PreviewDialog.vue'
import BindDevice from './bindDevice.vue'
import DeviceInfo from './deviceInfo.vue'
import batchDeleteAllSuccess from '@/utils/batchDeleteAllSuccess'

@Component({
  components: {
    BindDevice,
    pagination,
    editDialog,
    BindTags,
    DeviceInfo
  }
})
export default class Equip extends Vue {
  @Ref() readonly tableContiner: ElTable
  @Ref() readonly paginationNode: pagination
  @Ref() readonly editDialogNode: editDialog
  @Ref() readonly BindTagsNode: BindTags
  @Ref() readonly BindDeviceNode: BindDevice
  @Ref() readonly DeviceInfoNode: DeviceInfo
  @Prop({}) private areaId: string
  @Prop({ default: [] }) private areaList: any[]

  private deviceList: any[] = []
  private loading = false
  private sels: any[] = []
  private noNet: boolean | string = 'false'
  private noData = require('@/assets/images/dashboard/noData.png')
  private noNetImg = ''
  private pageSize = 20
  private successColor = variables.colorPrimary
  private primaryColorDisable = variables.colorDisabled
  private previewDialog = Modal(PreviewDialog)
  private previewLoading: Record<string, boolean> = {}
  filterData: AnyObj = { searchType: 'physicalId' }

  get rootConfig() {
    return this.$store.state.config?.root
  }
  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user?.scope
  }

  @Watch('areaId')
  watchAreaId(val: string) {
    this.areaId = val
    this.refresh()
  }

  @Watch('areaList')
  watchAreaList(val: any[]) {
    if (val.length) {
      this.editDialogNode.init(val)
    }
  }

  private getAreaName(location: any) {
    const area = treeFind(this.areaList, ({ id }) => location.areaId === id)

    return area ? area.name : ''
  }

  private async getLocatSearch(pageSize = 20, offset = 0) {
    const { bind, searchType, searchText } = this.filterData
    this.loading = true
    try {
      const opt: any = {
        limit: pageSize,
        offset: offset,
        areaIds: [this.areaId],
        cascade: true
      }
      if (bind !== '') {
        Object.assign(opt, { bind })
      }
      if (searchText && !searchText.match(/^[ ]*$/)) {
        const searchVal = searchText.replace(/\s+/g, '')
        Object.assign(opt, {
          [searchType]: searchType === 'types' || searchType === 'areaIds' ? [searchVal] : searchVal
        })
      }

      const resp = await this.$api.base.getDevicesSearch(opt)
      if (resp.data && resp.data.code === 0) {
        this.deviceList = resp.data.data
        this.paginationNode.init({ total: resp.data.count })
        tableScrollTop()
        this.$nextTick(() => {
          ;(this.$refs.tableRef as Vue & { doLayout: () => boolean }).doLayout()
        })
      }
    } catch (error) {
      if (!error.response) {
        this.noNet = true
        this.noNetImg = this.$store.state.dashboard.noNetImg
      }
    } finally {
      this.loading = false
    }
  }

  private async refresh() {
    this.paginationNode.pageNo = 0
    await this.getLocatSearch(this.pageSize)
  }

  private message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }

  private dateFormat(row: any, column: AnyObj) {
    const date = row[column.property]
    if (!date) {
      return '-'
    }
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }

  private pageChange(data: AnyObj) {
    this.pageSize = data.pageSize
    const offset = (data.pageNo - 1) * data.pageSize
    this.getLocatSearch(this.pageSize, offset)
  }

  private async clickDeleteAll() {
    if (this.sels.length) {
      this.$confirm('即将批量删除设备，删除后无法恢复，是否确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        loading: true,
        onConfirm: async () => {
          const res = await asyncPool(this.sels, ({ id }: any) => this.$api.base.deleteDevice(id))
          if (batchDeleteAllSuccess(res)) {
            this.$message({ message: '删除成功', type: 'success' })
          }

          await this.refresh()
        }
      }).catch(() => false)
    }
  }

  private clickEdit(list: any) {
    this.$nextTick(() => {
      this.editDialogNode.open('edit', list, '')
    })
  }
  clickAdd() {
    //根区域下不能创建点位
    let param: string | null = this.areaId
    if (this.areaId === this.root) {
      param = null
    }
    this.editDialogNode.open('add', {}, param)
  }
  private clickSeeDevice(device: any) {
    this.DeviceInfoNode.open(device)
  }
  private clickBindDevice({ id }: AnyObj) {
    this.BindDeviceNode.open(id)
  }
  private clickUnbindDevice({ id }: AnyObj) {
    this.$confirm('点击确定后区域和设备将解除绑定，是否确定解绑？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn',
      loading: true,
      onConfirm: async () => {
        const { data } = await this.$api.base.unbindDevice(id)
        if (data && data.code === 0) {
          this.message('解绑成功!', 'success')
          await this.refresh()
        }
      }
    }).catch(() => {})
  }

  private clickBindTags(value: AnyObj) {
    this.BindTagsNode.open(value)
  }

  private setPreviewLoading(id: string, loading: boolean) {
    this.previewLoading = { ...this.previewLoading, [id]: loading }
  }

  private async clickPreview(row: any) {
    this.setPreviewLoading(row.id, true)

    const { data } = await this.$api.base.getDevicePreview(row.id)

    this.setPreviewLoading(row.id, false)

    if (data.code) {
      this.$message({ message: data.error?.description, type: 'error' })
    } else if (data.type === 'application/json') {
      const { error } = await blobToJson(data)

      this.$message({ message: error?.description, type: 'error' })
    } else {
      const url = URL.createObjectURL(data)

      this.previewDialog.open({ url })
    }
  }

  private async clickDelete(row: AnyObj) {
    this.$confirm('即将删除设备，删除后无法恢复，是否确定删除？', {
      type: 'warning',
      loading: true,
      onConfirm: async () => {
        const { data }: any = await this.$api.base.deleteDevice(row.id)
        if (!data?.error) {
          this.$message({ message: '删除成功', type: 'success' })
          await this.refresh()
        }
      }
    }).catch(() => {})
  }

  private selsChange(sels: any) {
    this.sels = sels
  }
}
